import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCrownSparkleSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 22 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "crownsparklesolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2388 15.1118H3.18891C3.09603 15.1118 3.01538 15.0478 2.99418 14.9574L1.27606 7.62582C1.23682 7.4584 1.41318 7.32279 1.56491 7.40372L5.92954 9.73181C6.03306 9.78703 6.16166 9.74169 6.20766 9.63374L9.02986 3.01015C9.09894 2.84802 9.32876 2.84802 9.39785 3.01015L12.22 9.63374C12.266 9.74169 12.3946 9.78703 12.4982 9.73181L14.9831 8.40633C15.1622 8.31084 15.3812 8.33301 15.5374 8.46245L16.4865 9.24868C16.6327 9.36972 16.6977 9.56306 16.6544 9.7478L15.4335 14.9574C15.4123 15.0478 15.3317 15.1118 15.2388 15.1118ZM3.03059 16.6811C3.03059 16.543 3.14252 16.4311 3.28059 16.4311H15.1476C15.2856 16.4311 15.3976 16.543 15.3976 16.6811V17.5004C15.3976 17.6385 15.2856 17.7504 15.1476 17.7504H3.28059C3.14252 17.7504 3.03059 17.6385 3.03059 17.5004V16.6811Z", fill: "url(#paint0_linear_437_13458)" }),
  /* @__PURE__ */ jsx("g", { filter: "url(#filter0_i_437_13458)", children: /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.1543 2.15568C16.2364 2.12915 16.325 2.13009 16.4065 2.15836C16.488 2.18662 16.5581 2.24067 16.6062 2.31233L17.4795 3.61389C17.6675 3.8942 17.9239 4.12195 18.2244 4.27563C18.5249 4.42931 18.8596 4.50385 19.197 4.49221L20.7633 4.43824C20.8495 4.43534 20.9343 4.46058 21.0049 4.51015C21.0755 4.55972 21.128 4.63092 21.1545 4.713C21.1811 4.79508 21.1802 4.88356 21.152 4.96507C21.1237 5.04659 21.0698 5.1167 20.9982 5.16481L19.6968 6.03805C19.4165 6.2261 19.1888 6.48249 19.0351 6.78301C18.8814 7.08354 18.8069 7.41828 18.8186 7.75562L18.8726 9.32204C18.8755 9.40825 18.8503 9.49306 18.8007 9.56366C18.7512 9.63425 18.68 9.68678 18.5979 9.71332C18.5158 9.73985 18.4274 9.73894 18.3458 9.71073C18.2643 9.68252 18.1942 9.62853 18.1461 9.55694L17.2729 8.25539C17.0848 7.97508 16.8284 7.74733 16.5279 7.59364C16.2274 7.43996 15.8927 7.36542 15.5554 7.37706L13.9891 7.43103C13.9029 7.43393 13.8181 7.40869 13.7475 7.35912C13.6769 7.30955 13.6243 7.23835 13.5978 7.15627C13.5713 7.0742 13.5722 6.98571 13.6004 6.9042C13.6286 6.82269 13.6826 6.75257 13.7542 6.70446L15.0556 5.83122C15.3359 5.64318 15.5636 5.38678 15.7172 5.08626C15.8709 4.78573 15.9454 4.45099 15.9337 4.11365L15.8797 2.54723C15.8767 2.46099 15.9019 2.37612 15.9515 2.30548C16.001 2.23483 16.0722 2.18225 16.1543 2.15568Z", fill: "url(#paint1_linear_437_13458)" }) }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_i_437_13458", x: 13.5781, y: 2.13647, width: 7.5957, height: 8.09607, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 0.5 }),
      /* @__PURE__ */ jsx("feGaussianBlur", { stdDeviation: 0.5 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_437_13458" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_437_13458", x1: 8.96904, y1: 2.88855, x2: 8.96904, y2: 17.7504, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { offset: 0.175, stopColor: "#FFE3A3" }),
      /* @__PURE__ */ jsx("stop", { offset: 0.73, stopColor: "#FFB224" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint1_linear_437_13458", x1: 17.3794, y1: 10.1073, x2: 20.2551, y2: 4.59713, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#BC6BFC" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#F147EA" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgCrownSparkleSolid);
export default ForwardRef;
ForwardRef.displayName = "CrownSparkleSolidIcon";
