import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAiMeetingNotesGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "aimeetingnotesgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M12.4933 3.92924C12.4802 3.94254 12.4929 3.96463 12.511 3.96016C12.5249 3.95674 12.5296 3.93935 12.5195 3.92922C12.5123 3.92197 12.5005 3.92194 12.4933 3.92924Z", fill: "url(#paint0_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { d: "M3.74417 3.33826H4.84189C4.9475 3.33826 4.99416 3.54626 4.91098 3.61133C4.7399 3.74515 4.5863 3.9321 4.51397 4.17094C4.49278 4.24093 4.43148 4.29414 4.35835 4.29414H3.74417C3.16346 4.29414 2.6927 4.7649 2.6927 5.34561V13.4706C2.6927 14.0513 3.16346 14.5221 3.74417 14.5221H11.0971C11.6778 14.5221 12.1486 14.0513 12.1486 13.4706V10.1203C12.1486 10.08 12.1648 10.0414 12.1935 10.0132L12.8494 9.36934C12.9443 9.27619 13.1045 9.34341 13.1045 9.47638V13.4706C13.1045 14.5792 12.2057 15.478 11.0971 15.478H3.74417C2.63554 15.478 1.73682 14.5792 1.73682 13.4706V5.34561C1.73682 4.23698 2.63554 3.33826 3.74417 3.33826Z", fill: "url(#paint1_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { d: "M7.86559 7.41538C7.95658 7.31989 7.8889 7.1619 7.757 7.1619H4.50893C4.19218 7.1619 3.9354 7.41867 3.9354 7.73543C3.9354 8.05218 4.19218 8.30896 4.50893 8.30896H6.94979C6.99083 8.30896 7.03007 8.29214 7.05838 8.26244L7.86559 7.41538Z", fill: "url(#paint2_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { d: "M6.03704 9.98829C6.03704 9.90545 5.96988 9.83829 5.88704 9.83829H4.50893C4.19218 9.83829 3.9354 10.0951 3.9354 10.4118C3.9354 10.7286 4.19218 10.9853 4.50893 10.9853H5.88704C5.96988 10.9853 6.03704 10.9182 6.03704 10.8353V9.98829Z", fill: "url(#paint3_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { d: "M8.09185 1.77136C8.18269 1.77131 8.27093 1.80164 8.34254 1.85753C8.41416 1.91341 8.46503 1.99165 8.48708 2.07978L8.62714 2.64232C8.75526 3.15273 9.15374 3.55128 9.66405 3.67942L10.2265 3.81951C10.3148 3.84139 10.3932 3.8922 10.4492 3.96384C10.5053 4.03548 10.5358 4.12384 10.5358 4.21481C10.5358 4.30578 10.5053 4.39413 10.4492 4.46578C10.3932 4.53742 10.3148 4.58823 10.2265 4.61011L9.66405 4.7502C9.15374 4.87834 8.75526 5.2769 8.62714 5.7873L8.48708 6.34984C8.46521 6.43814 8.41441 6.51658 8.34278 6.57265C8.27114 6.62871 8.18281 6.65917 8.09185 6.65917C8.0009 6.65917 7.91256 6.62871 7.84093 6.57265C7.7693 6.51658 7.7185 6.43814 7.69663 6.34984L7.55657 5.7873C7.49392 5.53668 7.36436 5.30779 7.18172 5.12512C6.99908 4.94244 6.77023 4.81285 6.51965 4.7502L5.95722 4.61011C5.86893 4.58823 5.79051 4.53742 5.73446 4.46578C5.6784 4.39413 5.64795 4.30578 5.64795 4.21481C5.64795 4.12384 5.6784 4.03548 5.73446 3.96384C5.79051 3.8922 5.86893 3.84139 5.95722 3.81951L6.51965 3.67942C6.77023 3.61677 6.99908 3.48718 7.18172 3.3045C7.36436 3.12183 7.49392 2.89294 7.55657 2.64232L7.69663 2.07978C7.71867 1.99165 7.76955 1.91341 7.84116 1.85753C7.91278 1.80164 8.00102 1.77131 8.09185 1.77136Z", fill: "url(#paint4_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.2925 3.11553C13.7515 2.64902 14.5049 2.64749 14.9658 3.11214L15.6401 3.79188C16.0943 4.24972 16.0923 4.98724 15.6355 5.44253L9.44859 11.6094C9.22837 11.8289 8.92968 11.9522 8.61824 11.9522H7.27001C7.02057 11.9522 6.81836 11.7506 6.81836 11.502V10.1731C6.81836 9.86657 6.93898 9.5723 7.1543 9.35348L13.2925 3.11553ZM8.80986 10.9727C8.77023 11.0122 8.71959 11.0382 8.66512 11.0477L7.72824 10.1139C7.7392 10.0652 7.76358 10.0201 7.79919 9.98394L12.5351 5.17102L13.5828 6.21525L8.80986 10.9727Z", fill: "url(#paint5_linear_1813_20544)" }),
  /* @__PURE__ */ jsx("path", { d: "M8.58526 11.0228L7.71799 10.1384C7.62213 10.0407 7.62273 9.884 7.71932 9.78698L12.4446 5.04102C12.5422 4.94302 12.7008 4.94285 12.7986 5.04063L13.7033 5.94536C13.8012 6.04327 13.8009 6.20209 13.7026 6.29961L8.93984 11.0252C8.84142 11.1229 8.68234 11.1218 8.58526 11.0228Z", fill: "none" }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_1813_20544", x1: 7.42064, y1: 3.8162, x2: 7.42064, y2: 15.478, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#839AE5" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint1_linear_1813_20544", x1: 7.42064, y1: 3.8162, x2: 7.42064, y2: 15.478, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#839AE5" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint2_linear_1813_20544", x1: 7.42064, y1: 3.8162, x2: 7.42064, y2: 15.478, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#839AE5" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint3_linear_1813_20544", x1: 7.42064, y1: 3.8162, x2: 7.42064, y2: 15.478, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#839AE5" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint4_linear_1813_20544", x1: 8.66591, y1: 1.77136, x2: 6.10436, y2: 6.50981, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#A5B5EA" }),
      /* @__PURE__ */ jsx("stop", { offset: 0.835363, stopColor: "#3D61DD" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint5_linear_1813_20544", x1: 15.3959, y1: 3.19674, x2: 6.8539, y2: 11.9522, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#839AE5" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgAiMeetingNotesGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "AiMeetingNotesGradientSolidIcon";
