import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgPuzzleGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "puzzlegradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M9 3.15C9 2.40442 9.60442 1.8 10.35 1.8C11.0956 1.8 11.7 2.40442 11.7 3.15V3.6C11.7 4.09706 12.1029 4.5 12.6 4.5H15.3C15.7971 4.5 16.2 4.90295 16.2 5.4V8.1C16.2 8.59706 15.7971 9 15.3 9H14.85C14.1044 9 13.5 9.60442 13.5 10.35C13.5 11.0956 14.1044 11.7 14.85 11.7H15.3C15.7971 11.7 16.2 12.1029 16.2 12.6V15.3C16.2 15.7971 15.7971 16.2 15.3 16.2H12.6C12.1029 16.2 11.7 15.7971 11.7 15.3V14.85C11.7 14.1044 11.0956 13.5 10.35 13.5C9.60442 13.5 9 14.1044 9 14.85V15.3C9 15.7971 8.59706 16.2 8.1 16.2H5.4C4.90295 16.2 4.5 15.7971 4.5 15.3V12.6C4.5 12.1029 4.09706 11.7 3.6 11.7H3.15C2.40442 11.7 1.8 11.0956 1.8 10.35C1.8 9.60442 2.40442 9 3.15 9H3.6C4.09706 9 4.5 8.59706 4.5 8.1V5.4C4.5 4.90295 4.90295 4.5 5.4 4.5H8.1C8.59706 4.5 9 4.09706 9 3.6V3.15Z", fill: "url(#sj28dns)" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "sj28dns", x1: 1.8689, y1: 8.00005, x2: 15.1285, y2: 8.00005, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "#AA47F7" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#F147EA" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgPuzzleGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "PuzzleGradientSolidIcon";
