import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCrownSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "crownsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.24555 14.9119H16.2954C16.3883 14.9119 16.469 14.848 16.4902 14.7576L18.2083 7.42599C18.2475 7.25857 18.0712 7.12296 17.9194 7.20389L13.5548 9.53198C13.4513 9.5872 13.3227 9.54186 13.2767 9.43392L10.4545 2.81032C10.3854 2.64819 10.1556 2.64819 10.0865 2.81032L7.2643 9.43392C7.21831 9.54186 7.0897 9.5872 6.98618 9.53198L2.62155 7.20389C2.46983 7.12296 2.29346 7.25857 2.3327 7.42599L4.05082 14.7576C4.07202 14.848 4.15267 14.9119 4.24555 14.9119ZM4.33723 16.2313C4.19916 16.2313 4.08723 16.3432 4.08723 16.4813V17.3006C4.08723 17.4386 4.19916 17.5506 4.33723 17.5506H16.2042C16.3423 17.5506 16.4542 17.4386 16.4542 17.3006V16.4813C16.4542 16.3432 16.3423 16.2313 16.2042 16.2313H4.33723Z", fill: "url(#paint0_linear_437_4299)" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_437_4299", x1: 10.2705, y1: 2.68872, x2: 10.2705, y2: 17.5506, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { offset: 0.175, stopColor: "#FFEEC8" }),
    /* @__PURE__ */ jsx("stop", { offset: 0.73, stopColor: "#FED78F" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgCrownSolid);
export default ForwardRef;
ForwardRef.displayName = "CrownSolidIcon";
